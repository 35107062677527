export const apiDetails = {
  // baseUrl: "https://api.myclout.com/v1.3/",
  // baseUrl: 'https://apibeta.marketinsightout.com/v1.3/',
  // baseUrl: process.env.REACT_APP_API_BASE_URL,
  // baseUrl: 'http://localhost:3999/',
  baseUrl: "https://apibeta.myclout.com/v1.3/",
  // baseUrl: "https://api-stage.myclout.com/v1.3/"
  // baseUrl: "http://apidev1.myclout.com/v1.3/"
};

export const objectToFormData = (obj) => {
  const fData = new FormData();
  Object.keys(obj).forEach((key) => fData.append(key, obj[key]));

  return fData;
};

export const urlDetails = {
  prodUrl: "https://apps.myclout.com",
  devUrl: "https://qa.myclout.com",
};
